import React, { Fragment } from 'react';
import { translate } from '../Services/translation';
const sameCategory = (item, index, array) =>
    (index > 0 && array[index - 1].category === array[index].category) ||
    item.showAll;

const QuickSearchResult = ({ result, selectedItem, searchUrl }) => (
    
    <ul className="quick-search-result">
        {result &&
            result.map((item, index, array) => (
                <Fragment key={`${item.name}-${index}`}>
                    {item.category === 'NoHit' ||
                        sameCategory(item, index, array) ? null : (
                        <li className="quick-search-result__item quick-search-result__group-header">
                            {item.category}
                        </li>
                    )}
                    <li
                        className={`quick-search-result__item ${selectedItem === index
                            ? 'quick-search-result__item--selected'
                            : ''
                            }`}
                    >
                        <div
                            className={
                                item.showAll
                                    ? 'quick-search-result__show-all'
                                    : `quick-search-result__link ${item.url
                                        ? ''
                                        : 'quick-search-result__link--disabled'
                                    } row`
                            }
                        >
                            <div className="columns small-3 medium-2 large-2">
                                {item.hasImage && item.imageSource && (
                                    <img
                                        className="quick-search-result__image"
                                        src={item.imageSource}
                                    />
                                )}</div>
                            <div className="columns small-3 medium-5 large-6">
                                {
                                    item.showAll ?
                                        <a href={item.showAll ? searchUrl : item.url}> {item.name}</a>
                                        : item.name
                                }
                            </div>
                            {
                              !item.showAll && item.category !== 'NoHit' &&
                              <div className="columns small-6 medium-5 large-4 showdiv {item.category === 'NoHit'?:display:none}  " ><a className="button showbutton" href={item.showAll ? searchUrl : item.url}> {translate('search.show')}</a> </div>
                            }
                        </div>
                    </li>
                </Fragment>
            ))}
    </ul>
);

export default QuickSearchResult;
