import { SPECIFICATIONBUNDLE_SET_CURRENT_SPECIFICATIONS } from '../constants';

const defaultState = {
  specifications: [],
};

export const specificationBundle = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SPECIFICATIONBUNDLE_SET_CURRENT_SPECIFICATIONS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
