import { DOCUMENT_SET_CURRENT_DOCUMENTS } from '../constants';

const defaultState = {
  documents: [],
};

export const document = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DOCUMENT_SET_CURRENT_DOCUMENTS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
