import { VAT_RECEIVE ,VAT_LOAD_ERROR} from '../constants';

export const vat = (
    state = { includeVAT: true, isLoaded: false },
    action
) => {
    switch (action.type) {
        case VAT_RECEIVE:
        case VAT_LOAD_ERROR:
         
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
            
    }
   
};
