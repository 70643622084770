import { INGRID_RECEIVE } from '../constants';

export const ingrid = (
    state = { showInfo:false },
    action
) => {
  
      switch (action.type) {
        case INGRID_RECEIVE:
                return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
 
};