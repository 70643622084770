import {
    VARIANTS_RECEIVE,
    VARIANTS_ERROR,
   
} from '../constants';

import { error as errorReducer } from './Error.reducer';

const defaultState = {
    list: {},
    errors: {},
};

export const variant = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case VARIANTS_RECEIVE:    
            return {
                ...state,
                errors: {},
                ...payload,
            };
        case VARIANTS_ERROR:
            return {
                ...state,
                errors: errorReducer(state.errors, action),
            };
        default:
            return state;
    }
};
