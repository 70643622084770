import { SPAREPART_RECEIVE,SPAREPART_LOAD_ERROR } from '../constants';

export const sparepart = (
    state = { 
        Brand : "",
        Color : "",
        Description : "",
        Id : "",
        ImageUrl : "",
        IsInStock : "",
        Name : "",
        CampaignPrice : [],
        PriceItem : [],
        QuantityFieldId : "",
        ShowBuyButton : "",
        ShowQuantityField : "",
        Size : "",
        StockStatusDescription : "",
        Url : "",
        ShortDescription : "",
        LongDescription : "",
        VariantName : "",
        Sku : "",
        ProductBadges : [],
        VariantTagDetails : [],
        StockStatusColor : "",
        StockStatusText : "",
        StockStatusBalance : "",
        ProductStoreStatus : "",
        MinimumQuantity : 0,
        MaximumQuantity : 0,
        QuantityStepping : 0,
        IsMinimumQuantity : false,
        IsMaximumQuantity : false,
        IsQuantityStepping : "",
        ShowMonitorProducts : "",
        BundledVariants:[],
        IsProductexist:false,
        ButtonClass:"",
     },
    action
) => {
    switch (action.type) {
        case SPAREPART_RECEIVE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
