import { combineReducers } from 'redux';
import { cart } from './Reducers/Cart.reducer';
import { quickSearch } from './Reducers/QuickSearch.reducer';
import { navigation } from './Reducers/Navigation.reducer';
import { facetedSearch } from './Reducers/FacetedSearch.reducer';
import { checkout } from './Reducers/Checkout.reducer';
import { myPage } from './Reducers/MyPage.reducer';
import { vat } from './Reducers/Vat.reducer';
import { subscribeBlock } from './Reducers/subscribeBlock.reducer';
import {monitor} from './Reducers/Monitor.reducer';
import { sparepart } from './Reducers/Sparepart.reduser';
import { variant } from './Reducers/Variant.reducer';
import { showMoreText } from './Reducers/ShowMoreText.reducer';
import { related } from './Reducers/Related.reducer';
import { specification } from './Reducers/Specification.reducer';
import { specificationBundle } from './Reducers/SpecificationBundle.reducer';
import { document } from './Reducers/Document.reducer';
import { usp } from './Reducers/Usp.reducer';
import { ingrid } from './Reducers/ingrid.reducer';
const staticReducers = {
    cart,
    quickSearch,
    navigation,
    facetedSearch,
    checkout,
    myPage,
    vat,
    subscribeBlock,
    monitor,
    sparepart,
    variant,
    showMoreText,
    specification,
    related,
    usp,
    specificationBundle,
    document,
    ingrid
   
};

const app = combineReducers(staticReducers);

export const createReducer = (asyncReducers) => {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers,
    });
};

export default app;
