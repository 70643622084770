import React, { Fragment } from 'react';
import { translate } from '../Services/translation';
import { catchError } from '../Actions/Error.action';
import { toggle } from '../Actions/Cart.action';
import { useDispatch, useSelector } from 'react-redux';
import { receive, loadError, setMonitorProductDetails } from '../Actions/MonitorProduct.action';
const MonitorProduct = ({
    text, instock, variantid, id
}) => {
    
    const dispatch = useDispatch();
    const { status } = useSelector((state) => state.monitor) || {};
    const onClick = async ({ variantid }) => {
        try {

            const email = document.getElementById(variantid).value;
            dispatch(setMonitorProductDetails(email, variantid));

        } catch (ex) {
            dispatch(catchError(ex, (error) => loadError(error)));
             
        }
    };
    return (
        <div className='product-monitor'>     
            <label className='product-monitor__label' htmlFor="email">{translate('monitorproduct.label.text')}</label>
                <input type='email' className='form__input product-monitor__input' id={variantid} size="30" placeholder={translate('monitorproduct.placeholder.text')} />
           
            <a
                className="button showbutton product-monitor__button"
                onClick={() => onClick({
                    text, instock, variantid, id
                })}
            >
                {translate('monitorproduct.button.text')}
            </a>
            <span id="stat" >{status}</span>
        </div>
    );
};
export default MonitorProduct;
