import { get, post } from '../Services/http';
import { catchError } from './Error.action';
import { VAT_RECEIVE, VAT_LOAD_ERROR } from '../constants';

export const toggle = () => (dispatch, getState) => {
    dispatch(setVat(!getState().includeVAT));
};

export const loadError = (error) => ({
    type: VAT_LOAD_ERROR,
    payload: {
        error,
    },
});

export const receive = (vat) => ({

    type: VAT_RECEIVE,
    payload: {...vat, isLoaded:true}

});

export const setVatDetails = (includeVAT) => (dispatch, getState) => {

    return post('/api/utility/includevat', { includeVAT: includeVAT })
        .then((response) => response.json())
        .then((vat) => dispatch(receive(vat)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

export const getVatDetails = () => (dispatch, getState) => {
    return get('/api/utility/includevat')
        .then((response) => response.json())
        .then((vat) =>  dispatch(receive(vat)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};