import { SUBSCRIBE_RECEIVE,SUBSCRIBE_LOAD_ERROR } from '../constants';

export const subscribeBlock = (
    state = { subscribeEmail: "",subscribeMessage:"" },
    action
) => {
    switch (action.type) {
        case SUBSCRIBE_RECEIVE:
            return {
                ...state,
                ...action.payload,
            };
        case SUBSCRIBE_LOAD_ERROR:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
};
