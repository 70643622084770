import { get, post } from '../Services/http';
import { catchError } from './Error.action';
import { EMAIL_LOAD_ERROR, STATUS_RECEIVE } from '../constants';

export const loadError = (error) => ({
    type: EMAIL_LOAD_ERROR,
    payload: {
        error,
    },
});

export const receive = (monitorStatus) => ({
    type: STATUS_RECEIVE,
    payload: monitorStatus,
});

export const setMonitorProductDetails = (email, variantid) => (dispatch) => {

    return post('/api/monitor/AddMonitorProduct', { email: email, variantid: variantid })
        .then((response) => response.json())
        .then((status) => dispatch(receive(status)))
        .catch((ex) => dispatch(catchError(ex, (error) => loadError(error))));
};

