import {
   USP_SET_CURRENT_USPS,
    
} from '../constants';

const defaultState = {
    usps: []
  
  
};

export const usp = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USP_SET_CURRENT_USPS:      
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
