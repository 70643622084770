import { STATUS_RECEIVE, EMAIL_LOAD_ERROR } from '../constants';

export const monitor = (
    state = { status: "" },
    action
) => {
    switch (action.type) {
        case STATUS_RECEIVE:
        case EMAIL_LOAD_ERROR:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;

    }

};
