import {
   SHOWMORETEXT_SET_CURRENT_TEXT,
    
} from '../constants';

const defaultState = {
    text: '',
    shortText: ''
  
};

export const showMoreText = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOWMORETEXT_SET_CURRENT_TEXT:      
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
