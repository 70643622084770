import { RELATED_SET_CURRENT_LIST } from '../constants';

const defaultState = {
  complimentary: [],
  similar: [],
  accessories: [],
  includedInBundle: []
};


export const related = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RELATED_SET_CURRENT_LIST:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
  
};
