import React from 'react';
import NavigationItem from './NavigationItem';
import { useSelector } from 'react-redux';

const Navigation = () => {
    const contentLinks = useSelector((state) => state.navigation.contentLinks);
    const additionalLinks = useSelector((state) => state.navigation.additionalHeaderLinks);

    return <NavigationItem links={contentLinks} additionalHeaderLinks={additionalLinks} />;
};

export default Navigation;
