import React from 'react';

const CartIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.49975 2V5.99462H4V22H20V5.99462H16.4988V2H7.49975ZM8.99693 6H15V3.50241H8.99693V6ZM5.49848 20.5H18.5023V7.5H5.49848V20.5Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default CartIcon;
