import React, { useState, useEffect, useCallback, useRef } from "react";
import { translate } from "../Services/translation";
import { useDispatch, useSelector, } from 'react-redux';
import { setVatDetails, getVatDetails } from '../Actions/Vat.action';
import { load as loadCart } from '../Actions/Cart.action';
const ShowVat = () => {
  const [includeVAT, setVat] = useState(true);
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const vat = useSelector((state) => state.vat) || {};
  const setIsActive = useCallback(
    (includeVATVal) => {
      dispatch(setVatDetails(includeVATVal))
        .then(() => {
          setCookie('includeVat', includeVATVal, 365);
          setVat(includeVATVal);
          document.location.reload(true);
        });
    }, []);
  function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }
  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value.toString() || "") + expires + "; path=/";
  }
  const fetchData = () => {
    var myCookie = getCookie("includeVat");

    if (myCookie === "" || myCookie === null) {
      setCookie('includeVat', 'true', 365);
      setVat(getCookie("includeVat") === 'true' ? true : false)
    }
    else {
      var data = document.cookie.split(';').some((item) => item.trim().startsWith('includeVat='));
      setVat(getCookie("includeVat") === 'true' ? true : false)
      dispatch(getVatDetails());
    }
    return;
  }
  useEffect(() => {

    if (!firstRender.current) {
      return;
    }
    firstRender.current = false;
    fetchData();
  }, [vat]);

  return (
    <div className="header__show-vat">
      <div>
        <ul>
          <li>
            <input type="radio" name="radio" checked={includeVAT === true ? true : false} onChange={() => setIsActive(true)} />  {translate("price.includevat")}
          </li>
          <li>
            <input type="radio" name="radio" checked={includeVAT === false ? true : false} onChange={() => setIsActive(false)} />  {translate("price.excludevat")}
          </li>
        </ul>
      </div>
    </div>
    //   <div className="switch-private">
    //   <label className="switch mobile-view">
    //     <span>{includeVAT}</span>
    //     <input type="checkbox" id="includeVat" onChange={(e) =>setIsActive(!includeVAT )} checked={includeVAT === true ? true : false} />
    //     <span className="bussiness">{translate('website.public')}</span>
    //     <span className="private">{translate('website.private')}</span>
    //   </label>
    // </div>


  );
};
export default ShowVat;

