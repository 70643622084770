import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { searchFacetChange } from '../Actions/FacetedSearch.action';
import { translate } from '../Services/translation';
const FacetedSearchGroup = ({ group }) => {
    const list = useRef(null);
    const [isActive, setIsActive] = useState(false);
    return (
        <Fragment>
            <div className="accordion">
                <div className="accordion-item">
                    <ul className="faceted-search__group" ref={list}>
                        <div
                            className="faceted-search__group-header "
                            role="faceted-search-item-group"
                        >
                            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                                <div>{group.label}</div>
                                <div>{isActive ? '-' : '+'}</div>
                            </div>
                            {group.options &&
                                group.options.map((item, itemIndex, array) => (
                                    <li
                                        key={`${item.label}-${itemIndex}`}
                                        className="faceted-search__item"
                                        role="faceted-search-item"
                                    >
                                        {isActive &&
                                            <FacetedFilterCheckbox item={item} group={group} />
                                        }
                                    </li>
                                ))}
                        </div>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

const FacetedFilterCheckbox = ({ item, group }) => {
    const dispatch = useDispatch();
    const onChange = useCallback(
        (group, item) => dispatch(searchFacetChange(group, item)),
        [dispatch]
    );
    return (
        <label className="faceted-filter">
            <input
                className="faceted-filter__input"
                type="checkbox"
                onChange={(event) => onChange(group, item)}
                checked={
                    group.selectedOptions != null &&
                    group.selectedOptions.includes(item.id)
                }
            />
            <span className="faceted-filter__label">
                {item.label}
                {!isNaN(item.quantity) && item.quantity != null && (
                    <span className="faceted-filter__quantity">
                        &nbsp;({item.quantity})
                    </span>
                )}
            </span>
        </label>
    );
};

const FacetedSearch = () => {
    const dispatch = useDispatch();
    const { facetFilters, navigationTheme } = useSelector(
        (state) => state.facetedSearch
    );
    return (
        navigationTheme !== 'category' && (
            <ul className="faceted-search">
                {facetFilters && facetFilters.length > 0 &&
                    <h3 className="faceted-search-title"> {translate('facet.header.filter')}</h3>
                }
                {facetFilters &&
                    facetFilters.map((group, groupIndex) => (
                        <FacetedSearchGroup
                            key={`${group.label}-${groupIndex}`}
                            group={group}
                        />
                    ))}
            </ul>
        )
    );
};

export default FacetedSearch;
