import React, { Fragment, useRef } from 'react';
const NavigationItem = ({ links = [], additionalHeaderLinks = [], contentLink = null }) => {
  const menuRef = useRef(null);
  const toggleMenu = () => {
    menuRef.current.classList.toggle('navbar__menu--show');
  };
  const additionClass =
    contentLink && contentLink.attributes
      ? contentLink.attributes.cssValue
      : null;
  const selectedClass =
    contentLink && contentLink.isSelected ? 'navbar__link--selected' : '';
  const hasChildrenClass = links.length > 0 ? 'has-children' : null;
  const hasNameOrChildren = (link) =>
    link.name || (link.links || []).length > 0;

  return (
    <Fragment>
      {!contentLink ? (
        <a
          className="navbar__link--block navbar__icon--menu navbar__icon"
          onClick={toggleMenu}
        ></a>
      ) : (
        <Fragment>
          {contentLink.url ? (
            <a
              className={`navbar__link ${selectedClass} ${hasChildrenClass || ''
                } ${additionClass || ''}`}
              href={contentLink.url || '#'}
              dangerouslySetInnerHTML={{ __html: contentLink.name }}
            ></a>
          ) : (
            <div
              className="navbar__no-link"
              dangerouslySetInnerHTML={{ __html: contentLink.name }}
            ></div>
          )}
          {links.length > 0 && (
            <i
              className="navbar__icon--caret-right navbar__icon navbar__icon--open"
              onClick={toggleMenu}
            ></i>
          )}
        </Fragment>
      )}

      {links.length > 0 && (
        <ul className="navbar__menu" ref={menuRef}>
          <div className="navbar__menu-header">
            {!contentLink ? (
               <span
                className="navbar__icon navbar__icon--close"
                onClick={toggleMenu}
              ></span> 
            ) : (
              <Fragment>
                <i
                  className="navbar__icon--caret-left navbar__icon"
                  onClick={toggleMenu}
                ></i>
                <span
                  className="navbar__title"
                  onClick={toggleMenu}
                  dangerouslySetInnerHTML={{
                    __html: contentLink.name,
                  }}
                ></span>
              </Fragment>
            )}
          </div>
          {links.length > 0 &&
            links.map(
              (link, i) =>
                hasNameOrChildren(link) && (
                  <li
                    className={`navbar__item ${link.attributes !== undefined &&
                        link.attributes.cssValue !== undefined
                        ? link.attributes.cssValue + '-li'
                        : ''
                      }`}
                    key={`${link.name}-${i}`}
                  >
                    <NavigationItem links={link.links} contentLink={link} />{' '}
                  </li>
                )
            )}
          {additionalHeaderLinks.length > 0 &&
            additionalHeaderLinks.map(({ text, href }, index) => (
              <li className="additional-links"
                key={`subnavCompact-${index}`}
              >
                <a className="additional-links--link" href={href}>
                  {text}
                </a>
              </li>
            ))}
        </ul>
      )}
    </Fragment>
  );
};

export default NavigationItem;
